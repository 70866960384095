import AOS from "aos";
import smoothscroll from "smoothscroll-polyfill";

import "./src/css/style.css";
import "aos/dist/aos.css";

smoothscroll.polyfill();

AOS.init({
  duration: 600,
  once: true
});
