module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"UPoS","short_name":"UPoS","start_url":"/","background_color":"#ffffff","theme_color":"#9f015e","display":"minimal-ui","icon":"src/images/logo-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c42905fa39fb47435e1a9a45e374cb29"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.uvik.in"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
